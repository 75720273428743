<template>
  <v-container fluid style="font-family: NotoSanLao;">
    <v-card class="mb-5">
      <v-card-title class="ml-8">
        ສະແກນ QR Code ຫຼື ປ້ອນຕົວເລກ QR Code ໄດ້ທີ່ນີ້:
      </v-card-title>
      <v-card-text>
        <div class="input-container">
          <v-text-field
            ref="scannedInput"
            v-model="scannedData"
            outlined
            prepend-icon="mdi-qrcode-scan"
            placeholder="ສະແກນ ຫຼື ປ້ອນຕົວເລກ QR Code"
            append-icon="mdi-magnify"
            @input="handleScannedData"
            @click:append="searchManualInput"
          />
          <v-icon
            v-if="scannedData"
            small
            color="red"
            class="clear-icon"
            @click="handleClearInput"
          >
            mdi-backspace
          </v-icon>
        </div>
      </v-card-text>
      <h1
        v-if="notFound"
        style="color: red; margin-top: -20px;"
        class="ml-10 pb-3"
      >
        ບໍ່ພົບຂໍ້ມູນໃນລະບົບ!
      </h1>
    </v-card>
    <!-- <v-card class="mb-5">
      <h3 class="ml-8 pt-5" style="text-align: center;">
        ສະແກນ QR Code ຫຼື ປ້ອນຕົວເລກ QR Code ໄດ້ທີ່ນີ້
      </h3>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              ref="scannedInput"
              v-model="scannedData"
              outlined
              prepend-icon="mdi-qrcode-scan"
              placeholder="ສະແກນ QR Code ທີ່ນີ້"
              @input="handleScannedData"
            />
          </v-col>
          <v-col cols="12" md="6" class="d-flex align-center">
            <v-text-field
              v-model="manualInput"
              outlined
              prepend-icon="mdi-keyboard"
              placeholder="ປ້ອນ QR Code ທີ່ນີ້"
              append-icon="mdi-magnify"
              @click:append="searchManualInput"
            />
            <v-btn
              fab
              dark
              @click="searchManualInput"
              color="primary"
              class="ml-2 mb-8"
              ><v-icon right dark class="mr-2">
                mdi-magnify
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <h1
          v-if="notFound"
          style="color: red; margin-top: -20px;"
          class="ml-10 pb-3"
        >
          ບໍ່ພົບຂໍ້ມູນໃນລະບົບ!
        </h1>
      </v-card-text>
    </v-card> -->

    <v-card v-if="qrInfo" class="id-card">
      <transition name="fade" mode="out-in">
        <v-row :align="isSmallScreen ? 'center' : 'start'">
          <v-col cols="12" md="4">
            <v-img
              :src="qrCode"
              alt="Customer Passport"
              class="customer-photo clickable-image"
              width="250"
              height="250"
              @click="openImagePreview"
            />
            <h2
              v-if="new Date(qrInfo.expireDate) <= new Date()"
              class="ml-5 pt-5"
              style="color: red"
            >
              ສັນຍາສະບັບນີ້ໝົດກຳນົດແລ້ວ!
            </h2>
          </v-col>
          <v-col cols="12" md="8">
            <v-card-text>
              <div class="personal-info">
                <v-row>
                  <v-col cols="12" sm="6">
                    ID (ລະຫັດ) :
                    <span class="font-weight-black">{{ qrInfo.qrcode }}</span>
                  </v-col>
                  <v-col cols="12" sm="6">
                    Name (ຊື່):
                    <span class="font-weight-black">
                      {{ qrInfo.firstName }}
                    </span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    Surname (ນາມສະກຸນ):
                    <span class="font-weight-black">
                      {{ qrInfo.lastName }}</span
                    >
                  </v-col>
                  <v-col cols="12" sm="6">
                    Date of Birth (ວັນ, ເດືອນ, ປີເກີດ):
                    <span class="font-weight-black">{{
                      new Date(qrInfo.birthday).toLocaleString("en-GB", {
                        day: "numeric",
                        month: "numeric",
                        year: "numeric"
                      })
                    }}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    Email (ອີເມວ):
                    <span class="font-weight-black"> {{ qrInfo.email }}</span>
                  </v-col>
                  <v-col cols="12" sm="6">
                    Phone (ເບີໂທລະສັບ):
                    <span class="font-weight-black">{{ qrInfo.phone }}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    Passport No (ເລກໜັງສືເດີນທາງ):
                    <span class="font-weight-black">{{
                      qrInfo.passportNo
                    }}</span>
                  </v-col>
                  <v-col cols="12" sm="6">
                    Product (ປະກັນ):
                    <span class="font-weight-black">{{
                      qrInfo.productType
                    }}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    Package (ແພັກເກັດ):
                    <span class="font-weight-black">
                      {{ qrInfo.productPackage }}</span
                    >
                  </v-col>
                  <v-col cols="12" sm="6">
                    Type (ປະເພດ):
                    <span class="font-weight-black"> {{ qrInfo.buyType }}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    Amount (ຈຳນວນ):
                    <span class="font-weight-black">
                      ₭ {{ qrInfo.amount.toLocaleString("en-US") }}</span
                    >
                  </v-col>
                  <v-col cols="12" sm="6">
                    Status (ສະຖານະ):
                    <span
                      :class="[
                        'font-weight-black',
                        {
                          'status-green': qrInfo.status === 'PAID',
                          'status-red': qrInfo.status !== 'PAID'
                        }
                      ]"
                      >{{ qrInfo.status }}</span
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    Create date (ວັນທີເລີ່ມ):
                    <span class="font-weight-black">{{
                      new Date(qrInfo.createAt).toLocaleString("en-GB", {
                        day: "numeric",
                        month: "numeric",
                        year: "numeric"
                      })
                    }}</span>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    :class="{
                      'expired-background':
                        new Date(qrInfo.expireDate) <= new Date()
                    }"
                  >
                    Expired date (ວັນທີສີ້ນສຸດ):
                    <span class="font-weight-black">{{
                      new Date(qrInfo.expireDate).toLocaleString("en-GB", {
                        day: "numeric",
                        month: "numeric",
                        year: "numeric"
                      })
                    }}</span>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-col>
        </v-row>
      </transition>
      <v-btn class="mb-5 ml-5" color="primary" @click="printQRInfo">
        <v-icon>mdi-printer</v-icon>
        Print
      </v-btn>
    </v-card>
    <v-dialog v-model="imagePreviewDialog" max-width="80%">
      <v-card>
        <v-card-title>
          <span class="mr-auto">Preview</span>
          <v-btn icon class="mr-5" @click="rotateImage">
            <v-icon>mdi-crop-rotate</v-icon>
          </v-btn>
          <v-btn icon @click="closeImagePreview">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="text-center">
          <v-img
            :src="qrCode"
            alt="Customer Passport"
            class="enlarged-image"
            :style="{ transform: `rotate(${imageRotation}deg)` }"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import QRCode from "qrcode";

export default {
  // components: { ScanDetail },
  data() {
    return {
      scannedData: "", // Holds the scanned data
      qrInfo: null,
      qrCode: null,
      qrNumber: "",
      notFound: false,
      showClearIcon: false,
      manualInput: "",
      focusedInput: "scannedData", // Set the initial focused input
      imagePreviewDialog: false,
      imageRotation: 0
    };
  },
  computed: {
    isSmallScreen() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    statusClass() {
      return {
        "font-weight-black": true, // Always apply this class
        "status-green": this.qrInfo.status === "PAID", // Apply this class if status is 'PAID'
        "status-red": this.qrInfo.status !== "PAID" // Apply this class if status is not 'PAID'
      };
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.focusedInput === "scannedData") {
        this.$refs.scannedInput.focus();
      }
    });
  },
  methods: {
    async handleScannedData() {
      // const scannedContent = this.scannedData.trim();
      // if (scannedContent !== "") {
      //   await this.readQR(scannedContent);
      // }
      this.showClearIcon = this.scannedData.length > 0;
      const content = this.scannedData.trim();
      if (content.length < 13) {
        this.notFound = false;
        // Handle your manual input logic here if needed
      } else {
        await this.readQR(content);
        this.focusedInput = "scannedData";
      }
    },
    async searchManualInput() {
      const manualContent = this.scannedData.trim();
      // const manualContent = this.manualInput.trim();
      if (manualContent !== "") {
        await this.readQR(manualContent);
      }
    },
    handleClearInput() {
      this.scannedData = "";
    },
    async readQR(qrcode) {
      try {
        const response = await axios.get(
          `https://node-qrcode-scanner.onrender.com/readQR/${qrcode}`
        );

        this.qrInfo = response.data;
        const qrContent = response.data.qrcode;
        const qrCodeDataUrl = await QRCode.toDataURL(qrContent);
        this.qrCode = qrCodeDataUrl;
        this.focusedInput = "scannedData";
        this.scannedData = "";
        this.manualInput = "";
        this.notFound = false;
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.qrInfo = null;
          this.qrCode = null; // Clear the previous QR code image
          this.focusedInput = "scannedData";
          this.scannedData = "";
          this.notFound = true;
        } else {
          console.error(error);
        }
      }
    },
    openImagePreview() {
      this.imagePreviewDialog = true;
    },

    closeImagePreview() {
      this.imagePreviewDialog = false;
      this.imageRotation = 0; // Reset image rotation when closing the preview
    },

    rotateImage() {
      this.imageRotation += 90; // Rotate image by 90 degrees
    },

    async printQRInfo() {
      try {
        // Ensure that the QR code image is generated before printing
        await this.readQR(this.qrInfo.qrcode);

        // Create a new window for printing
        const printWindow = window.open("", "_blank");

        // Construct the content to be printed
        const printContent = `
          <div class="qr-info-container">
            <h2>QR Code Content</h2>
            <p>${this.qrInfo.qrcode}</p>
            <img class="qr-code-image" src="${this.qrCode}" alt="QR Code" />
            <h4>Last Name: ${this.qrInfo.lastName}</h4>
            <h4>First Name: ${this.qrInfo.firstName}</h4>
            <h4>Phone: ${this.qrInfo.phone}</h4>
            <h4>Email: ${this.qrInfo.email}</h4>
            <h4>Date of birth: ${this.qrInfo.birthday}</h4>
            <h4>Passport Number: ${this.qrInfo.passportNo}</h4>
            <h4>Product: ${this.qrInfo.productType}</h4>
            <h4>Package: ${this.qrInfo.productPackage}</h4>
            <h4>Type: ${this.qrInfo.buyType}</h4>
            <h4>Amount: ${this.qrInfo.amount}</h4>
            <h4>Status: ${this.qrInfo.status}</h4>
          </div>
          <style>
            .qr-info-container {
              display: flex;
              flex-direction: column;
              align-items: center;
            }
            .qr-code-image {
              width: 200px;
              height: 200px;
            }
          </style>
        `;

        // Set the content in the new window
        await printWindow.document.write(printContent);

        // Print the content
        await printWindow.print();
        // Set focus to scannedData input
        this.scannedData = "";
        this.focusedInput = "scannedData";

        // Focus on the input field after printing
        this.$nextTick(() => {
          this.$refs.scannedInput.focus();
        });
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.input-container {
  position: relative;
}
.clear-icon {
  position: absolute;
  top: 32%;
  right: 50px;
  transform: translateY(-50%);
  cursor: pointer;
}
.customer-photo {
  /* max-width: 100%;
  max-height: auto; */
  border-radius: 4px;
  margin-left: 1rem;
  margin-top: 1.5rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.clickable-image {
  cursor: pointer;
}

.enlarged-image {
  max-width: 80%;
  // max-height: 80vh;
  margin: 0 auto;
}

.personal-info {
  padding: 1rem;
  border-radius: 5px;
  margin: 0;
  font-family: "NotoSanLao", sans-serif !important;
  // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  .personal-info:hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }

  .status-green {
    background-color: green;
    color: white;
    padding: 2px 4px;
    border-radius: 5px;
  }

  .status-red {
    background-color: red;
    color: white;
    padding: 2px 4px;
    border-radius: 5px;
  }
  .expired-background {
    background-color: red !important;
    color: white;
    border-radius: 5px;
    max-width: fit-content;
  }
}

/* .info-row {
  margin-left: 1rem;
} */
</style>
